/* Buttons */

.btn-width,
.btn-metal,
.btn-danger,
.btn-primary,
.btn-outline-metal,
.btn-outline-danger,
.btn-outline-primary {
  min-width: 150px;
}

.btn-danger,
.btn-outline-danger:hover {
  color: #fff !important;
}


/* Tables */

.table h2 {
  margin: 0;
}

.table td {
  position: relative;
}

.table tr:last-child td {
  border: 0;
}

.table th {
  padding: .55rem .9rem 0.45rem .9rem;
}

.table thead:not(:first-child) th {
  background: var(--bs-lighter);
}

.gradient::before,
.table thead:first-child:before {
  top: 0;
  width: 0;
  left: 50%;
  right: 50%;
  content: '';
  position: absolute;
  transition: all .2s;
  background: var(--gradient);
}

.gradient::before,
.table:not(.nogradient) thead:first-child:before {
  left: 0;
  right: 0;
  width: 100%;
  height: calc(var(--gradient-height) + .025rem);
}

.table .borderless td {
  background: var(--bs-lightest);
}


/* Accordions */

.accordion h3 {
  margin: 0;
}

.accordion-body {
  margin-bottom: .3rem;
}

.accordion-static {
  pointer-events: none;
}

.accordion-static::after {
  display: none;
}

.accordion-button {
  overflow: hidden;
  font-size: 1.1rem;
  position: relative;
  border-bottom: 1px solid transparent;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  border-color: var(--bs-border-color);
}

.accordion-item {
  margin-bottom: 1.5rem;
  box-shadow: var(--bs-box-shadow);
  border-radius: var(--bs-border-radius-sm);
}

.accordion-button:not(.accordion-static) {
  cursor: pointer;
}

.accordion-button:not(.nogradient):before {
  top: 0;
  width: 0;
  left: 50%;
  right: 50%;
  content: '';
  position: absolute;
  transition: all .2s;
  background: var(--gradient);
}

.accordion-button:not(.collapsed):before {
  left: 0;
  right: 0;
  width: 100%;
  height: calc(var(--gradient-height) + .025rem);
}

.accordion-form .accordion-button:before {
  display: none;
}

.accordion-form {
  border-color: #fff;
}

.accordion-form .accordion-item {
  border-color: #fff;
  box-shadow: none;
  margin-bottom: 0;
}

.accordion-form .accordion-body {
  background: #f5f9ff;
  margin-bottom: 0;
  padding-top: 0;
}

.accordion-form .accordion-button {
  border: none;
  box-shadow: none;
}

.accordion-form .accordion-button:not(.collapsed),
.accordion-form .accordion-button:hover {
  color: var(--bs-accordion-active-color);
  background: #f5f9ff;
  font-weight: bold;
  color: #0f3c7f;
  border-color: #deeafa
}

.accordion-form .accordion-button:not(:hover)::after {
  display: none
}


/* Modals */

.modal-list {
  height: 21rem;
  overflow-y: scroll;
}

.modal-list::-webkit-scrollbar {
  width: .5rem;
  -webkit-appearance: none;
  background: var(--bs-gray-300);
  border-radius: var(--bs-border-radius);
}

.modal-list::-webkit-scrollbar-thumb {
  background: var(--bs-gray-500);
  border-radius: var(--bs-border-radius);
}

.modal-list .form-switch .form-check-input {
  margin-top: 2px;
}


/* Others */

.pe-link {
  cursor: pointer;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.bold {
  font-weight: bold;
}

.notifs .dropdown-menu {
  min-width: 400px;
  box-shadow: var(--bs-box-shadow);
}

.prefs .dropdown-menu {
  min-width: 300px;
  box-shadow: var(--bs-box-shadow);
}

.flip-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.2s;
}

.flip-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: 0.2s;
}

.hov-primary:hover {
  color: var(--bs-primary);
}

label.description {
  border: 1px solid var(--bs-white);
}

label.description:hover {
  border: 1px solid var(--bs-primary);
  background: var(--bs-light)
}

.setup {
  max-width: 80%;
}

.w-80 {
  width: 80%
}

.mw-95 {
  width: 95%
}

.form-colors {
  color: #2C538E;
  background: #F5F9FF;
  border-color: #DEEAFA !important;
}

.prefix-sterling:before {
  content: '£';
}
.no-after:after  {
  display: none
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    vertical-align: middle;
    margin-left: 10px; /* Space between switch and label */
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .dark-mode-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: #666; /* Adjust color as needed */
    margin-left: 25px;
  }
  /* DARK MODE STYLES */

.bg-basic {
  background: var(--bs-light);
}

[data-bs-theme=dark] hr {
  color: #2e2e2f;
  opacity: 1;
}

[data-bs-theme=dark] .shadow {
  box-shadow: 0 0 0.75rem 0 rgb(0 0 0 / 33%) !important;
}

[data-bs-theme=dark] a:not(.btn) {
  color: var(--bs-body-color)
}

[data-bs-theme=dark] .table a:hover {
  color: var(--bs-primary);
}

[data-bs-theme=dark] .bg-logo {
  background: #fff;
}

[data-bs-theme=dark] .nav-link:hover {
  background: #131015;
  color: #fff
}

[data-bs-theme=dark] .bg-basic,
[data-bs-theme=dark].bg-basic {
  background: #131015;
}

[data-bs-theme=dark] .bg-white {
  background: var(--bs-body-bg) !important;
}

[data-bs-theme=dark] .border-white {
  border-color: var(--bs-body-bg) !important;
}

[data-bs-theme=dark] .table thead:not(:first-child) th {
  background: #222024;
  padding: .4rem .9rem .5rem .9rem;
}

[data-bs-theme=dark] .table {
  border-color: #29252e;
  border-radius: 10px !important;
}

[data-bs-theme=dark] .badge {
  border-color: transparent !important;
}

[data-bs-theme=dark] header .breadcrumb * {
  color: #838383;
}

[data-bs-theme=dark] .alert {
  border: none !important;
}

[data-bs-theme=dark] .btn-outline-primary:not(:hover) {
  color: #ffffff;
  border-color: #ffffff;
}

[data-bs-theme=dark] .prefs .dropdown-menu,
[data-bs-theme=dark] .notifs .dropdown-menu {
  background-color: #222024!important;
  box-shadow: 0 0 0.75rem 0 rgb(0 0 0 / 33%);
}

[data-bs-theme=dark] .form-colors {
  background: #131115;
  border-color: transparent !important;
}

[data-bs-theme=dark] .nav-link.active {
  color: #a887ff
}

[data-bs-theme=dark] .btn:disabled,
[data-bs-theme=dark] .btn.disabled,
[data-bs-theme=dark] fieldset:disabled .btn {
  color: #424242
}

[data-bs-theme=dark] .form-control {
  border-color: #2e2e2f
}

[data-bs-theme=dark] .accordion-button:not(.collapsed) {
  color: var(--bs-body-color) !important;
  border-color: #2e2e2f;
}

[data-bs-theme=dark] .btn-light {
  color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .btn-light:hover {
  background-color: initial;
}

[data-bs-theme=dark] .table .borderless td {
  background: #151316;
}

[data-bs-theme=dark] .gradient::before,
[data-bs-theme=dark] .table:not(.nogradient) thead:first-child:before {
  display: none;
}

[data-bs-theme=dark] .nav-pills .nav-link.active,
[data-bs-theme=dark] .nav-pills .show>.nav-link {
  background-color: #161419;
  border-radius: 6px;
}

[data-bs-theme=dark] .table-hover>tbody>tr:hover>* {
  --bs-table-bg-state: #161419;
  transition: all .5s;
}

[data-bs-theme=dark] .bg-success-subtle {
  background-color: #243622 !important;
  color: #82d37e !important;
}

[data-bs-theme=dark] .bg-secondary-subtle {
  background-color: #2b1d77 !important;
  color: #baa1ff !important;
}

[data-bs-theme=dark] .bg-primary-subtle {
  background: #193566 !important;
  color: #abcdff !important;
}

[data-bs-theme=dark] .bg-warning-subtle {
  background-color: #40281f !important;
  color: #cfac3a !important;
}

[data-bs-theme=dark] .bg-danger-subtle {
  background-color: #3d1d1c !important;
  color: #d29696 !important;
}

[data-bs-theme=dark] .progress,
.progress-stacked {
  background-color: #484848;
}

[data-bs-theme=dark] .progress-bar {
  background: #123775;
}

/* 
--bs-btn-bg: #123775;

#b44c19 */

[data-bs-theme=dark] .border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) #2c2c2c !important;
}

[data-bs-theme=dark] .accordion-item {
  overflow: hidden;
  border-radius: 10px !important;
}

[data-bs-theme=dark] .accordion-button:before {
  display: none;
}

[data-bs-theme=dark] .btn-primary {
  --bs-btn-bg: #123775;
  --bs-btn-color: #FFFFFF;
  --bs-btn-border-color: #123775;
}

[data-bs-theme=dark] .table-info > :not(caption) > * > * {
  background-color: #18161a;
  border-color: #4a4a4a!important;
}

[data-bs-theme=dark] .text-gray {
  color: #767676!important;
}

[data-bs-theme=dark] .text-metal {
  color: #9899a1!important;
}

[data-bs-theme=dark] .text-dark {
  color: #969598!important;
}

[data-bs-theme=dark] [type="radio"]:not(:checked), 
[data-bs-theme=dark] .setup [type="checkbox"]:not(:checked) {
  background: #211f23;
}

[data-bs-theme=dark] .show-when-open {
  color: #67a0f6!Important;
}

[data-bs-theme=dark] .form-check-input:not(:checked) {
  border: 2px solid #3d3b3f;
}
[data-bs-theme=dark] .bg-lighter {
  background: #161419!important
}
 
[data-bs-theme=dark] .border-bottom,
[data-bs-theme=dark] .border-end {
  border-color: #2c2c2c!important;
}
.message-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* space between messages */
}

.message-container.user {
  justify-content: flex-end;
  margin-top: 40px;
}

.message-container.user .initials {
  margin-right: 10px; /* Space between the initial and the text */
}

.message-container.admin {
  justify-content: flex-start;
}

.message-container.admin .initials {
  margin-left: 10px; /* Space after the text */
}

.initials-admin {
  width: 25px;
  height: 25px;
  background-color: #7e8287; /* Example color */
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.initials-user {
  width: 25px;
  height: 25px;
  background-color: #7e8287; /* Example color */
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.text {
  background-color: #f8f9fa; /* Example color */
  padding: 8px 12px;
  border-radius: 16px;
  max-width: 80%;
}
.error-message {
  color: red;
  margin: 10px 0;
  font-weight: bold;
}
.pagination
{
  justify-content: center;
  align-items: center;
  margin-left:10rem;
  position: absolute;
  bottom: 0;
}
.agreement-text {
  color: black;
  font-weight: bold;
  margin: 0;
  padding-left: 8px; 
}

.underline {
  text-decoration: underline;
}
.agreement-container {
  display: flex;
  align-items: center;
}

.footer-container {
  background-color: #f5f5f5;
  padding: 20px;
  font-size: 14px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 10px;
 
}

.footer-links a {
  margin-left: 10px;
  text-decoration: none;
  color: #333;
}

