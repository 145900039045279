p,
pre {
  line-height: 1.9;
}

.accordion-button {
  background: white;
}

.svg-text {
  font-weight: 500;
  font-family: Satoshi, sans-serif;
}

.rating,
.donuts circle:not(.none) {
  animation-duration: 1s;
}

.rating,
.donuts circle {
  animation-name: fill-array;
  animation-fill-mode: forwards;
}

@keyframes fill-array {
  from {
    strokeOpacity: 0;
    stroke-dasharray: 0 304;
  }

  to {
    strokeOpacity: 1;
  }
}

.counter {
  width: 100px;
  overflow: hidden;
  font: 800 40px system-ui;
}

.numbers {
  width: auto;
  display: flex;
  animation-timing-function: steps(10);
  animation: countNumber 4s infinite alternate;
}

.numbers div {
  flex: 0 0 100px;
  text-align: center;
}

@keyframes countNumber {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: -1000px;
  }
}

.live-icon {
  z-index: 1;
  width: .7rem;
  height: .7rem;
  position: relative;
  border-radius: 50%;
  top: calc(50% - 5px);
  display: inline-block;
  margin: 0 .8rem 0 .5rem;
  background: var(--bs-danger);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.live-icon:before {
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 50%;
  animation: live 2s ease-in-out infinite;
  background-color: rgba(var(--bs-danger-rgb), 0.6);
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}

[data-bs-theme=dark] .accordion-button {
  background: inherit;
}

[data-bs-theme=dark] pre {
  border: 1px solid #413f43!important;
  background-color: #272529!important;
}

[data-bs-theme=dark] .dark-white {
  fill: #fff!important
}

[data-bs-theme=dark] .dark-light {
  fill: #a4a3a5!important
}

[data-bs-theme=dark] .dark-none {
  fill: none!important
}

[data-bs-theme=dark] .donuts circle.text-light {
  stroke: none;
}