.finding img {
  display: block;
  max-width: 100%;
  margin: 2rem auto 1rem;
  box-shadow: var(--bs-box-shadow);
}

.finding figure {
  text-align: center;
}

.finding figcaption {
  font-weight: bold;
  padding-top: .8rem;
}

.finding pre {
  text-wrap: pretty;
  padding: 1.5rem!important;
}
.finding strong {
  display: inline; /* Ensure strong remains inline */
  margin: 0;       /* Remove any inherited margins */
  padding: 0;      /* Remove any inherited paddings */
  line-height: normal; /* Ensure consistent line height */
}