/* Font Import */

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: Satoshi;
  src: url(./Fonts/Satoshi-Regular.woff2) format('woff2');
}

@font-face {
  font-weight: 500;
  font-style: bold;
  font-family: Satoshi;
  src: url(./Fonts/Satoshi-Medium.woff2) format('woff2');
}

@font-face {
  font-weight: 700;
  font-style: bold;
  font-family: Satoshi;
  src: url(./Fonts/Satoshi-Medium.woff2) format('woff2');
}


/* Root Styles */

:root {
  --aside: 1.5rem;
  --sidebar: 14rem;
  --header: 4.8125rem;
  --gradient-height: .125rem;
  --gradient: linear-gradient(to right, var(--bs-secondary), var(--bs-danger) 73%, var(--bs-secondary));
}

#root {
  background: var(--bs-light);
}

#root:before {
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  z-index: 99999;
  position: fixed;
  background: var(--gradient);
  height: var(--gradient-height);
}

main {
  min-height: 100vh;
  max-width: 180rem;
  padding: calc(var(--header) + .75rem) 1rem 0 calc(var(--sidebar) + 1rem);
}

h1 {
  min-height: 42px;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--bs-body-color)!important;
}

img,
svg {
  display: inline-block;
}

select:not(:disabled),
input:not([type="text"]):not([type="number"]) {
  cursor: pointer;
}

strong {
  display: block;
  margin-bottom: .5rem;
}

select,
option {
  white-space: normal;
}

[type="radio"]:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

[type="radio"]:not(:checked), .setup [type="checkbox"]:not(:checked) {
  background: #fff;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
  padding-left: 60%
}

[type="date"] {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='gray'%3E%3Crect width='16' height='16' rx='2'/%3E%3Crect width='14' height='12' rx='1' fill='%23fff' x='1' y='3'/%3E%3Ccircle cx='6.5' cy='6' r='1'/%3E%3Ccircle cx='9.5' cy='6' r='1'/%3E%3Ccircle cx='12.5' cy='6' r='1'/%3E%3Ccircle cx='6.5' cy='9' r='1'/%3E%3Ccircle cx='9.5' cy='9' r='1'/%3E%3Ccircle cx='12.5' cy='9' r='1'/%3E%3Ccircle cx='3.5' cy='9' r='1'/%3E%3Ccircle cx='6.5' cy='12' r='1'/%3E%3Ccircle cx='9.5' cy='12' r='1'/%3E%3Ccircle cx='3.5' cy='12' r='1'/%3E%3C/svg%3E") 97% 50% no-repeat;
  background-size: 1.3rem;
}


/* Header Styles */

header {
  z-index: 99;
  height: var(--header);
  padding: 0 .75rem 0 calc(var(--sidebar) + 1rem);
}

header .dropdown-toggle:after {
  content: none;
}

header .breadcrumb * {
  font-weight: 500;
  color: var(--bs-gray);
}


/* Sidebar Styles */

aside {
  z-index: 999;
  height: 100vh;
  width: var(--sidebar);
  background: var(--bs-body-bg);
  padding: 0 var(--aside) var(--aside) var(--aside);
}

aside h6 {
  opacity: .6;
  margin: 1.6rem 0 .7rem 0;
  text-transform: uppercase;
}

aside .nav-link {
  position: relative;
  margin: 0 calc(-1 * var(--aside))
}

aside .nav-link:hover {
  background: var(--bs-light);
}

aside .nav-link.active {
  font-weight: bold;
  color: var(--bs-secondary);
}

aside .nav-link svg {
  margin: -0.2rem 0.75rem 0 0;
}

aside .active:hover:after {
  top: 0;
  bottom: 0;
}

aside .active:after {
  right: 0;
  top: 20%;
  bottom: 20%;
  content: '';
  position: absolute;
  border-radius: 50rem;
  transition: top 400ms, bottom 400ms;
  border: solid 1.5px var(--bs-secondary);
}

