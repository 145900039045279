// Functions
@import "../node_modules/bootstrap/scss/functions";


// Base Colors
$red: #EB2E25;
$blue: #0060EF;
$gray: #A4ACB8;
$dark: #4A4A4A;
$white: #FFFFFF;
$light: #F4F5F7;
$black: #000000;
$metal: #353951;
$green: #0DA109;
$yellow: #FFC303;
$purple: #4826A4;
$orange: #FF6300;
$lighter: #FAFAFB;
$lightest: #FAFBFD;
$gray-dark: #171C37;


// Base Grays
$gray-100: $lighter;
$gray-200: $light;
$gray-300: #ECECEC;
$gray-400: #D5D5D5;
$gray-500: $gray;
$gray-600: #6C757D;
$gray-700: $dark;
$gray-800: $metal;
$gray-900: $gray-dark;


// Color Maps
$info: $light;
$danger: $red;
$primary: $blue;
$success: $green;
$caution: $yellow;
$warning: $orange;
$secondary: $purple;


// Theme Colors
$theme-colors: (
  "gray": $gray,
  "dark": $dark,
  "info": $info,
  "light": $light,
  "metal": $metal,
  "danger": $danger,
  "lighter": $lighter,
  "success": $success,
  "caution": $caution,
  "warning": $warning,
  "primary": $primary,
  "lightest": $lightest,
  "secondary": $secondary,
  "gray-dark": $gray-dark,
);


// Theme Color Variables
$danger-bg-subtle: tint-color($danger, 91%);
$success-bg-subtle: tint-color($success, 91%);
$warning-bg-subtle: tint-color($warning, 91%);
$caution-bg-subtle: tint-color($warning, 91%);
$primary-bg-subtle: tint-color($primary, 91%);
$secondary-bg-subtle: tint-color($secondary, 91%);


// Theme Colors - Subtle Border Variables
$danger-border-subtle: tint-color($danger, 80%);
$success-border-subtle: tint-color($success, 80%);
$warning-border-subtle: tint-color($warning, 80%);
$primary-border-subtle: tint-color($primary, 80%);
$secondary-border-subtle: tint-color($secondary, 80%);


// Theme Colors - Text Emphasis Variables
$primary-link: shade-color($primary, 40%);
$danger-text-emphasis: shade-color($danger, 60%);
$success-text-emphasis: shade-color($success, 50%);
$warning-text-emphasis: shade-color($warning, 60%);
$caution-text-emphasis: shade-color($warning, 60%);
$primary-text-emphasis: shade-color($primary, 60%);
$secondary-text-emphasis: shade-color($secondary, 50%);


// Root 
$body-bg: $white;
$body-color: $dark;
$font-size-base: 1rem;
$focus-ring-width: .1rem;
$focus-ring-color: tint-color($primary, 30%);
$box-shadow: 0 0 .75rem 0 rgba(0, 0, 0, 0.05);
$box-shadow-lg: 0 0 .75rem 0 rgba(0, 0, 0, 0.1);
$font-family-sans-serif: Satoshi, Helvetica, Arial, sans-serif;


// Layout & Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .3,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
  6: $spacer * 10
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1450px,
  xxl: 1700px
);
$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8
);
$enable-negative-margins: true;
$container-max-widths: $grid-breakpoints;


// Typography
$display-font-sizes: (
  1: 2.4rem,
  2: 2.2rem,
  3: 2rem,
  4: 1.8rem,
  5: 1.6rem,
  6: 1.4rem
);
$font-weight-bold: 500;
$small-font-size: .9rem;
$font-weight-normal: 400;
$display-line-height: 1.7;
$display-font-weight: 500;
$paragraph-margin-bottom: 1.5rem;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;


// Headings
$headings-color: inherit;
$headings-line-height: 2;
$headings-margin-bottom: 1.1rem;
$h6-font-size: $font-size-base * 0.8;
$h5-font-size: $font-size-base * 0.9;
$h4-font-size: $font-size-base * 1.0;
$h3-font-size: $font-size-base * 1.1;
$h2-font-size: $font-size-base * 1.2;
$h1-font-size: $font-size-base * 1.3;
$headings-font-weight: $font-weight-bold;


// Borders
$hr-opacity: .5;
$border-width: 1px;
$border-radius: .375rem;
$border-color: $gray-300;
$hr-color: $gray-400;
$border-radius-sm: .2rem;
$border-radius-lg: .5rem;
$border-radius-xl: .9rem;
$border-radius-xxl: 2rem;
$border-radius-pill: 50rem;


// Transitions 
$transition-fade: opacity .300s ease-in-out;


// Links
$link-decoration: none;
$link-color: $body-color;
$link-hover-color: $primary;


// Navs
$nav-link-padding-y: .8rem;
$nav-link-padding-x: 1.5rem;
$nav-link-hover-color: $secondary;
$navbar-light-toggler-icon-bg: null;


// Tabs
$nav-tabs-link-active-color: $dark;
$nav-tabs-link-active-bg: $secondary-bg-subtle;


// Pills
$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $dark;
$nav-pills-link-active-bg: $secondary-bg-subtle;


// Breadcrumb
$breadcrumb-font-size: .9rem;
$breadcrumb-margin-bottom: 0;


// Badges
$badge-color: $dark;
$badge-font-size: .9rem;
$badge-padding-y: .5rem;
$badge-padding-x: 1.25rem;
$badge-border-radius: $border-radius-pill;


// Buttons
$btn-padding-y-sm: 0;
$btn-padding-x-sm: 0;
$btn-padding-y: .5rem;
$btn-padding-x: 1.25rem;
$btn-font-size-sm: .9rem;
$btn-link-color: $primary;
$btn-border-width: .125rem;
$btn-active-bg-shade-amount: 5%;
$btn-link-disabled-color: $gray-500;
$btn-link-hover-color: $primary-link;
$btn-border-radius: $border-radius-pill;
$btn-font-weight: $font-weight-bold !important;


// Tables
$table-hover-bg: $lighter;
$table-th-font-weight: 500;
$table-cell-padding-x-sm: .9rem;
$table-cell-padding-y-sm: .5rem;
$table-border-color: $border-color;
$table-cell-vertical-align: middle;
$table-cell-padding-x: $table-cell-padding-x-sm;
$table-cell-padding-y: $table-cell-padding-x-sm;


// Progress
$progress-height: .4rem;
$progress-bar-bg: $success;


// Modals
$modal-md: 650px;
$modal-xl: 850px;
$modal-backdrop-opacity: .7;
$modal-backdrop-bg: #1E2534;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1.75rem;
$modal-dialog-margin-y-sm-up: 5rem;
$modal-footer-margin-between: 3.5rem;
$modal-content-border-radius: $border-radius-xl;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;
$modal-inner-padding: calc($modal-header-padding-y * 0) $modal-header-padding-x calc($modal-header-padding-y * 2.25) $modal-header-padding-x;


// Close Button
$btn-close-opacity: 1;
$btn-close-width: 1rem;
$btn-close-color: $black;
$btn-close-padding-y: .75rem;
$btn-close-focus-shadow: none;
$btn-close-height: $btn-close-width;
$btn-close-hover-opacity: $btn-close-opacity;
$btn-close-padding-x: $btn-close-padding-y !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}' fill-opacity='0.6'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;


// Popovers
$popover-bg: $metal;
$popover-max-width: 220px;
$popover-body-color: $white;
$popover-border-color: transparent!important;
$popover-border-radius: $border-radius;
$popover-inner-border-radius: $border-radius;


// Forms
$form-switch-width: 2.5em;
$form-switch-color: $white;
$form-select-font-size: .9rem;
$form-check-input-width: 1.5em;
$form-check-input-bg: $gray-500;
$form-label-margin-bottom: 1rem;
$form-check-margin-bottom: .6rem;
$form-switch-focus-color: $white;
$form-switch-checked-color: $white;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: $success;
$form-switch-padding-start: $form-switch-width + 1em;
$form-check-input-border: 2px solid $form-check-input-bg;


// Input
$input-padding-y: .75rem;
$input-padding-x: .75rem;
$input-border-width: .09rem;
$input-btn-font-size: .9rem;
$input-btn-padding-y: .375rem;
$input-btn-font-size-lg: 1rem;
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 2.5rem;
$input-focus-border-color: $focus-ring-color;


// Accordion
$accordion-body-padding-y: 1.5rem;
$accordion-body-padding-x: 1.75rem;
$accordion-button-padding-y: .75rem;
$accordion-button-padding-x: 1.5rem;
$accordion-button-active-color: $dark;
$accordion-button-active-bg: $lighter;
$accordion-border-color: transparent;
$accordion-icon-active-color: $secondary;
$accordion-button-focus-box-shadow: none;
$accordion-border-radius: $border-radius-sm;
$accordion-button-focus-border-color: $border-color;
$accordion-inner-border-radius: $accordion-border-radius;


// List Groups
$list-group-active-color: $dark;
$list-group-item-padding-x: 1rem;
$list-group-item-padding-y: 1.5rem;
$list-group-active-border-color: $dark;
$list-group-hover-bg: $secondary-bg-subtle;
$list-group-border-radius: $border-radius-lg;



// Dropdowns
$dropdown-padding-y: 0;
$dropdown-border-width: 0;
$dropdown-box-shadow: $box-shadow;
$dropdown-border-radius: $border-radius-xl;


// Alert
$alert-border-radius: $border-radius-sm;


// Code
$code-color: $metal;


// Variables
@import "../node_modules/bootstrap/scss/variables";


// Dark Theme
@import "./dark";
$colors: map-remove($colors, "teal", "cyan", "black", "pink", "gray-dark", "tertiary", "indigo");


// Configuration
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";


// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";


// Helpers
@import "../node_modules/bootstrap/scss/helpers";


// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";